function pauseCarouselVideoPlayback(iframe, carouselId) {
    if (typeof iframe == 'undefined' ||
        typeof carouselId == 'undefined' ||
        iframe.src == '') {
        return;
    }

    var videoCode = getYoutubeVideoCode(iframe.src);
    if (videoCode == null) {
        return;
    }

    player = new YT.Player(iframe, {
        videoId: videoCode,
        events: {
            'onStateChange': function (event) {
                var owl = $(carouselId); // '.owl-carousel'
                if (owl != null) {
                    if (event.data == YT.PlayerState.PLAYING) {
                        // not 'autoplay.stop.owl'
                        owl.trigger('stop.autoplay.owl');
                    }
                    else if (event.data == YT.PlayerState.ENDED) {
                        // not 'play.owl.autoplay'
                        owl.trigger('play.autoplay.owl');
                    }
                }
            }
        }
    });
}

function getYoutubeVideoCode(url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return null;
    }
}